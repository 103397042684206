<div
  [hidden]="!isModalDisplay"
  [ngClass]="{ hidden: !isModalDisplay }"
  #container
  class="iu-modal-background"
  (mousedown)="initClose($event)"
  [ngStyle]="
    angularStyle !== undefined && angularStyle['iu-modal-background'] ? angularStyle['iu-modal-background'] : ''
  "
  (contextmenu)="$event.preventDefault()"
  [attr.data-cy-contentType]="contentType">
  <div
    class="{{ 'iu-modal ' + modalClass }}"
    [ngStyle]="angularStyle !== undefined && angularStyle['iu-modal'] ? angularStyle['iu-modal'] : ''">
    <div
      [ngClass]="[title !== undefined && title !== null ? 'border' : '', 'iu-modal-header']"
      [ngStyle]="angularStyle !== undefined && angularStyle['iu-modal-header'] ? angularStyle['iu-modal-header'] : ''">
      <div class="close-cross" [ngStyle]="{ float: title !== undefined && title !== null ? 'unset' : 'right' }">
        <iu-button-ui
          class="btn-close"
          btnType="default"
          [isStandalone]="true"
          (click)="close($event, true)"
          icon="icon-close"
          data-cy="modal-close-btn"></iu-button-ui>
      </div>
      <div *ngIf="title !== undefined && title !== null" class="title">{{ title | translate }}</div>
      <p-progressBar
        *ngIf="isLoading"
        [mode]="'indeterminate'"
        [value]="0"
        [showValue]="false"
        [styleClass]="'loading-progressbar'"></p-progressBar>
    </div>

    <div
      class="iu-modal-body"
      [ngStyle]="angularStyle !== undefined && angularStyle['iu-modal-body'] ? angularStyle['iu-modal-body'] : ''">
      <ng-content></ng-content>
    </div>
    <div
      *ngIf="hasCloseBtn || hasFooter"
      class="iu-modal-footer"
      [ngStyle]="angularStyle !== undefined && angularStyle['iu-modal-footer'] ? angularStyle['iu-modal-footer'] : ''">
      <ng-content select="[footer]"></ng-content>
      <button
        *ngIf="hasCloseBtn"
        pButton
        class="p-button p-button-secondary"
        (click)="close($event, true)"
        label="{{ closeBtnLabel | translate }}"
        icon="fa fa-ban"></button>
    </div>
  </div>
</div>
